import React, { useState } from 'react'
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'
import { SPORTS } from '../../helpers/constants'

const GameSelection = ({
  selectGame,
  selectedGame,
  games,
  replayStatus
}) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="inline">
      <DropdownToggle
        caret
        className="sport-endpoint-dropdown"
        disabled={replayStatus === 'started' || replayStatus === 'paused'}
      >
        {
          !selectedGame
            ? 'SELECT A GAME TO REPLAY' : selectedGame.game
        }

      </DropdownToggle>
      <DropdownMenu className="sport-endpoint-menu" style={{ backgroundColor: 'rgb(41, 50, 63)', border: '0px', height: '400px', overflowY: 'scroll'}}>
        <DropdownItem header className="bord-bottom-grey bord-top-grey" style={{ backgroundColor: 'rgb(41, 50, 63)' }}>MLB At Bat</DropdownItem>
        {
          games.map(game => {
            if (game.sport !== SPORTS.MLB || game.type !== 'inplay') return null

            return (
              <DropdownItem
                className="swish-dropdown-item"
                key={game.eventId}
                onClick={() => selectGame(game)}
              >
                {game.game}
              </DropdownItem>
            )
          })
        }

        <DropdownItem header className="bord-bottom-grey bord-top-grey" style={{ backgroundColor: 'rgb(41, 50, 63)' }}>MLB ROG</DropdownItem>
        {
          games.map(game => {
            if (game.sport !== SPORTS.MLB || game.type !== 'rog') return null

            return (
              <DropdownItem
                className="swish-dropdown-item"
                key={game.eventId}
                onClick={() => selectGame(game)}
              >
                {game.game}
              </DropdownItem>
            )
          })
        }

        <DropdownItem header className="bord-bottom-grey bord-top-grey" style={{ backgroundColor: 'rgb(41, 50, 63)' }}>NFL Full Game</DropdownItem>
        {
          games.map(game => {
            if (game.sport !== SPORTS.NFL || game.type !== 'all') return null

            return (
              <DropdownItem
                className="swish-dropdown-item"
                key={game.eventId}
                onClick={() => { selectGame(game) }}
              >
                {game.game}
              </DropdownItem>
            )
          })
        }

        <DropdownItem header className="bord-bottom-grey bord-top-grey" style={{ backgroundColor: 'rgb(41, 50, 63)' }}>NBA</DropdownItem>
        {
          games.map(game => {
            if (game.sport !== SPORTS.NBA) return null

            return (
              <DropdownItem
                className="swish-dropdown-item"
                key={game.eventId}
                onClick={() => selectGame(game)}
              >
                {game.game}
              </DropdownItem>
            )
          })
        }
      </DropdownMenu>
    </Dropdown>
  )
}

export default GameSelection

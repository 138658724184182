import React, { Component } from 'react'

import io from 'socket.io-client'

import { Row, Column } from 'simple-flexbox'

import {
  ReplayCard,
  GameSelectionCard,
  StartReplayCard
} from '../components/replay'

import { AccountContext } from '../context/account-context'

import {
  replaySocketURL,
  replayClientURL
} from '../helpers/replay'

import { SPORTS } from '../helpers/constants'

const games = [
  {
    eventId: 630975, game: 'STL vs MIL (9/24/2020)', sport: SPORTS.MLB, type: 'rog'
  },
  {
    eventId: 566086, game: 'OAK vs LAA (3/28/2019)', type: 'inplay', sport: SPORTS.MLB
  },
  // Super Bowl
  {
    eventId: 2449057, game: 'KC vs PHI (2/12/2023)', type: 'all', sport: SPORTS.NFL
  },

  // Week 15
  {
    eventId: 2448816, game: 'SEA vs SF (12/15/2022)', type: 'all', sport: SPORTS.NFL
  },

  {
    eventId: 2448873, game: 'BUF vs MIA (12/17/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448875, game: 'CLE vs BAL (12/17/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448878, game: 'MIN vs IND (12/17/2022)', type: 'all', sport: SPORTS.NFL
  },

  {
    eventId: 2448881, game: 'NO vs ATL (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448883, game: 'WAS vs NYG (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448821, game: 'CHI vs PHI (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448824, game: 'HOU vs KC (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448818, game: 'CAR vs PIT (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448829, game: 'JAX vs DAL (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448832, game: 'NYJ vs DET (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448838, game: 'DEN vs ARI (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448844, game: 'LAC vs TEN (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448849, game: 'TB vs CIN (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },
  {
    eventId: 2448856, game: 'LV vs NE (12/18/2022)', type: 'all', sport: SPORTS.NFL
  },

  {
    eventId: 2448861, game: 'GB vs LAR (12/19/2022)', type: 'all', sport: SPORTS.NFL
  },

  {
    eventId: 2265970, game: 'Ala vs Ohi (1/12/2021)', type: 'all', sport: SPORTS.CFB
  },
  {
    eventId: 2360001, game: 'MIL vs PHO (7/20/2021)', type: 'all', sport: SPORTS.NBA
  }
]

class Replay extends Component {
  constructor (props) {
    super(props)

    this.socket = null
    this.updateTimeout = null

    this.state = {
      selectedGame: null,
      selectedType: 'inplay',
      replayStatus: 'stopped',
      replayCurrentIndex: 0,
      replayURLBase: null,
      replayURL: null,
      gamestateData: null,
      timestampUpdatesInLastSecond: 0,
      context: null,
      checkedForReplaysInProgress: false
    }

    // Bindings
    this.selectGame = this.selectGame.bind(this)
    this.selectType = this.selectType.bind(this)
    this.updateReplayStatus = this.updateReplayStatus.bind(this)
    this.startReplay = this.startReplay.bind(this)
    this.updateReplayCurrentIndex = this.updateReplayCurrentIndex.bind(this)
  }

  componentDidMount () {
    const { apikey } = this.context

    if (apikey) {
      this.checkForReplaysInProgress()
    }
  }

  componentDidUpdate (_, prevState) {
    const { apikey } = this.context
    const {
      checkedForReplaysInProgress, replayURLBase, selectedType, selectedGame
    } = this.state

    if (apikey && !checkedForReplaysInProgress) {
      this.checkForReplaysInProgress()
    }
    if (prevState.selectedType !== selectedType) {
      const url = replayClientURL(
        replayURLBase,
        apikey,
        selectedGame,
        selectedType
      )

      this.setState({
        replayURL: url
      })
    }
  }

  componentWillUnmount () {
    if (this.socket) this.socket.disconnect()
    this.socket = null
  }

  selectGame (selectedGame) {
    this.setState({
      selectedGame
    })
  }

  selectType (selectedType) {
    this.setState({
      selectedType
    })
  }

  returnSocketArgs () {
    const { selectedGame } = this.state
    const { apikey } = this.context

    return {
      eventId: selectedGame.eventId,
      apikey: apikey.apikey,
      sport: selectedGame.sport,
      type: selectedGame.type
    }
  }

  checkForReplaysInProgress () {
    const { apikey } = this.context
    const { checkedForReplaysInProgress } = this.state

    if (!apikey || checkedForReplaysInProgress) return

    this.setState({
      checkedForReplaysInProgress: true
    })

    this.socket = io(replaySocketURL(), {
      transports: [ 'websocket' ],
      reconnection: true,
      query: `apikey=${ apikey.apikey }`
    })

    this.socket.on('connect', () => {
      // emitting to check for replay for each sport
      // there should only be 1 replay running per apikey anyways
      games.forEach(game => {
        this.socket.emit('replays',
          { sport: game.sport, type: game.type, apikey: apikey.apikey }
        )
      })
    })

    this.socket.on('gamestate', data => {
      this.setState({
        gamestateData: data
      })
    })

    this.socket.on('replays', data => {
      if (data && data.length > 0) {
        this.setState({
          selectedGame: games.find(g => parseInt(g.eventId) === parseInt(data[ 0 ]))
        })
        this.socket.emit('isPaused', this.returnSocketArgs())

        this.socket.on('pauseStatus', isPaused => {
          this.setState({
            replayStatus: isPaused ? 'paused' : 'started'
          }, () => {
            this.socket.emit('gamestate', this.returnSocketArgs())
            this.socket.emit('resume', this.returnSocketArgs())

            this.socket.on('currentIndex', timestamp => {
              this.setState({
                context: timestamp
              })
              this.findClosestIndex(timestamp.live)
            })

            this.socket.on('successfullyStopped', () => {
              setTimeout(() => {
                this.setState({
                  replayStatus: 'stopped'
                })
              }, 500)
            })

            this.socket.on('successfullyPaused', () => {
              setTimeout(() => {
                this.setState({
                  replayStatus: 'paused'
                })
              }, 500)
            })

            this.socket.on('replayURL', replayURLBase => {
              const { selectedGame, selectedType } = this.state
              const url = replayClientURL(
                replayURLBase,
                apikey,
                selectedGame,
                selectedType
              )

              this.setState({
                replayURLBase,
                replayURL: url
              })
            })
          })
        })
      }
    })
  }

  startReplay () {
    const { apikey } = this.context

    this.socket = io(replaySocketURL(), {
      transports: [ 'websocket' ],
      reconnection: true,
      query: `apikey=${ apikey.apikey }`
    })

    this.socket.on('connect', () => {
      this.socket.emit('start', this.returnSocketArgs())
      this.socket.emit('gamestate', this.returnSocketArgs())
    })

    this.socket.on('gamestate', data => {
      this.setState({
        gamestateData: data
      })
    })

    this.socket.on('replayURL', replayURLBase => {
      const { selectedGame, selectedType } = this.state
      const url = replayClientURL(
        replayURLBase,
        apikey,
        selectedGame,
        selectedType
      )

      this.setState({
        replayURLBase,
        replayURL: url
      })
    })

    this.socket.on('successfullyStarted', () => {
      this.setState({
        replayStatus: 'started'
      })
    })

    this.socket.on('successfullyStopped', () => {
      setTimeout(() => {
        this.setState({
          replayStatus: 'stopped'
        })
      }, 500)
    })

    this.socket.on('successfullyPaused', () => {
      setTimeout(() => {
        this.setState({
          replayStatus: 'paused'
        })
      }, 500)
    })

    this.socket.on('currentIndex', timestamp => {
      this.setState({
        context: timestamp
      })
      this.findClosestIndex(timestamp.live)
    })
  }

  findClosestIndex (live) {
    if (!live) return

    const { gamestateData } = this.state
    let index

    for (let i = 0; i < gamestateData.length; i += 1) {
      if (parseInt(live) >= parseInt(gamestateData[ i ].gamestate.timestamp)) {
        index = i
      }
    }

    if (index === -1 || index === undefined) return
    this.setState({
      replayCurrentIndex: index
    })
  }

  updateTimestampTimeout () {
    this.updateTimeout = setTimeout(() => {
      this.setState({
        timestampUpdatesInLastSecond: 0
      })
      clearTimeout(this.updateTimeout)
    }, 1000)
  }

  updateReplayCurrentIndex (timestamp) {
    const { selectedGame, timestampUpdatesInLastSecond } = this.state
    const { apikey } = this.context

    this.setState({
      timestampUpdatesInLastSecond: timestampUpdatesInLastSecond + 1
    }, () => {
      this.updateTimestampTimeout()
    })

    if (timestampUpdatesInLastSecond > 2) {
      return
    }

    this.findClosestIndex(timestamp)

    this.socket.emit('updateIndex', {
      timestamp,
      eventId: selectedGame.eventId,
      apikey: apikey.apikey,
      sport: selectedGame.sport,
      type: selectedGame.type
    })
  }

  updateReplayStatus (status) {
    if (status === 'initializing') {
      this.startReplay()
    } else if (status === 'stopping') {
      this.setState({
        context: null
      })
      this.socket.emit('stop', this.returnSocketArgs())
    } else if (status === 'paused') {
      this.socket.emit('pause', this.returnSocketArgs())
    } else if (status === 'resume') {
      this.startReplay()
      this.setState({ replayStatus: 'started' })

      return
    }

    this.setState({ replayStatus: status })
  }

  render () {
    const {
      selectedGame,
      replayStatus,
      replayCurrentIndex,
      gamestateData,
      context,
      replayURL,
      selectedType
    } = this.state

    return (
      <div>
        <Column flexGrow={1}>
          <Row wrap horizontal="spaced" breakpoints={{ 900: 'column' }}>
            <Column
              className="pl-4 pb-sm-0 min-width"
              flex="1"
              horizontal="start"
            >
              <h1 className="pt-3 klavika-regular white"><b>Replay</b></h1>
              <div className="mar-bottom-20 bg-120 light-blue klavika-regular">
                Replay endpoints recreate the real-time flow of data from endpoints to facilitate the testing & validation of Swish API integrations.  Once you have selected the match you would like to replay & clicked "Start Replay", you will see a visual recreation of the state for the selected endpoint, as well as a unique replay.dev.swishanalytics.com URL you can use to make requests as you would to our production endpoints in a production-ready environment.
                <br />
                <br />

                Both the visual recreation & the unique API URL are time-synced as shown by the
                {' '}
                <code>replayContext</code>
                {' '}
object fields shown in the API response, so as you make requests and update the time-slider for a given replay, you will see how data changes in the specified endpoint throughout the life-cycle of the selected match, such as adjustments to market probabilities & changes to various status values.
              </div>
              <GameSelectionCard
                selectGame={this.selectGame}
                selectedGame={selectedGame}
                games={games}
                replayStatus={replayStatus}
              />

              <StartReplayCard
                selectedGame={selectedGame}
                replayStatus={replayStatus}
                updateReplayStatus={this.updateReplayStatus}
              />

              <ReplayCard
                replayStatus={replayStatus}
                socket={this.socket}
                replayCurrentIndex={replayCurrentIndex}
                updateReplayCurrentIndex={this.updateReplayCurrentIndex}
                gamestateData={gamestateData}
                context={context}
                replayURL={replayURL}
                sport={selectedGame ? selectedGame.sport : null}
                selectedGame={selectedGame}
                selectType={this.selectType}
                selectedType={selectedType}
              />

            </Column>
          </Row>
        </Column>
      </div>
    )
  }
}

Replay.contextType = AccountContext

export default Replay

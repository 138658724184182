import { SPORTS } from "./constants"

export const replaceFakeAPIKeyText = (text, apikey) => {
  if (!apikey || !text) return text

  return text.replace('YOUR-API-KEY-HERE', apikey)
}

export const formatKeyWords = text => {
  if (!text) return ''

  return text
    .replace('Inplay', 'In-Play')
    .replace('Prematch', 'Pre-Match')
    .replace('Accumulators', 'Pre-Built Parlays')
    .replace('Bet-request', 'Bet Request')
    .replace('Specialteams', 'Special Teams')
}

export const formatTeamNickname = name => (name
  ? name.split(' ').join('_').toLowerCase()
  : '')

export const capitalizeFirstLetter = string => (
  string.charAt(0).toUpperCase() + string.slice(1)
)

export const toTitleCase = str => {
  if (!str) return ''

  return str.replace(/\w\S*/g, txt => (
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  ))
}

export const toAllCaps = str => {
  if (!str) return ''

  return str.toUpperCase()
}

export const boldString = (str, search) => (
  str.replace(RegExp(search, 'g'), `<b>${ search }</b>`)
)

export const toTitleCaseAndBold = (str, search) => {
  if (!str) return ''

  let updatedStr = str.replace(/\w\S*/g, txt => (
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  ))

  search.split(' ').forEach(s => {
    if (s === '') return
    updatedStr = updatedStr.replace(
      RegExp(toTitleCase(s), 'g'), `<b>${ toTitleCase(s) }</b>`)
  })

  return updatedStr
}

export const pathnameToEndpoint = p => {
  if (!p) return null

  return p.replace('/docs/', '').split('-').join('/')
    .replace('bet/request', 'bet-request')
}

export const capitalize = text => {
  if (!text) return text

  return text.toLowerCase()
    .split('-')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const capitalizeSports = str => {
  const sports = Object.values(SPORTS)

  let updatedStr = str

  sports.forEach(sport => {
    updatedStr = updatedStr.replace(sport, sport.toUpperCase())
  })

  return updatedStr
}

export const capitalizeSportsFromURL = url => {
  const sports = Object.values(SPORTS)

  return url.split('-').reduce((a, c) => {
    if (!c) return a

    let idk = a

    if (sports.includes(c.toLowerCase())) {
      idk += `${ c.toUpperCase() } `
    } else {
      idk += `${ capitalize(c) } `
    }

    return idk
  }, '')
}

export const formatDescriptionsForBool = desc => {
  if (!desc) return ''

  return desc.replace('true', '<code>true</code>')
    .replace('false', '<code>false</code>')
}

export const returnSportIcon = sport => {
  if (sport === SPORTS.NBA || sport === SPORTS.CBB) {
    return 'enhanced-sports enhanced-sports-basketball'
  }
  if (sport === SPORTS.NFL || sport === SPORTS.CFB) {
    return 'enhanced-sports enhanced-sports-football'
  }
  if (sport === SPORTS.MLB) {
    return 'enhanced-sports enhanced-sports-baseball'
  }
  if (sport === SPORTS.NHL) {
    return 'enhanced-sports enhanced-sports-hockey'
  }
}

export const returnSportLogo = sport => {
  const sportLogos = {
    [ SPORTS.NBA ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nba.png',
    [ SPORTS.NFL ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nfl.png',
    [ SPORTS.MLB ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/mlb.png',
    [ SPORTS.NHL ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nhl.png',
    [ SPORTS.CBB ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cbb.png',
    [ SPORTS.CFB ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cfb.png',
    [ SPORTS.ATP ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/atp-white.png',
    [ SPORTS.WTA ]: 'https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/wta.png'
  }

  return sportLogos[ sport ]
}

export const orderSideBarItemsMiddleware = (items, productSuite) => {
  let orderByLU

  if (productSuite === 'metadata') {
    orderByLU = {
      Games: 1,
      Players: 2,
      Teams: 3
    }
  } else if (productSuite === 'player props') {
    orderByLU = {
      'Pre-Match': 1,
      'In-Play': 2,
      'At-Bat': 3,
      'Head-to-Head': 4,
      Alternates: 5,
      Historical: 6
    }
  } else if (productSuite === 'match markets') {
    orderByLU = {
      'Pre-Match': 1,
      'In-Play': 2
    }
  } else if (productSuite === 'bet builder') {
    orderByLU = {
      'Bet Request': 1
    }
  } else if (productSuite === 'pre built parlays') {
    orderByLU = {
      'Pre-Match': 1,
      'In-Play': 2
    }
  } else if (productSuite === 'results') {
    orderByLU = {
      Offense: 1,
      Batter: 1,
      Goalie: 1,
      'Pre-Match': 1,
      Defense: 2,
      Pitcher: 2,
      Skater: 2,
      'Special Teams': 3,
      'Match Markets': 4
    }
  }

  return items.map(item => {
    item.orderByAttribute = orderByLU[ item.sidebarTitle ] || 10

    return item
  })
}

export const removeHeaderTags = input => {
  const regex = /<h[1-6][^>]*>(.*?)<\/h[1-6]>/gi

  return input.replace(regex, '$1')
}

export const truncateString = string => `${ string.slice(0, 100) }...`

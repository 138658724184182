import React from 'react'
import { SPORTS } from '../../helpers/constants'

const returnSportIcon = (sport) => {
  if (sport === SPORTS.NBA || sport === SPORTS.CBB) {
    return 'enhanced-sports enhanced-sports-basketball'
  }

  if (sport === SPORTS.NFL || sport === SPORTS.CFB) {
    return 'enhanced-sports enhanced-sports-football'
  }

  if (sport === SPORTS.NHL) return 'enhanced-sports enhanced-sports-hockey'

  if (sport === SPORTS.MLB) return 'enhanced-sports enhanced-sports-baseball'
}

const SportItem = ({ sport, filters, updateFilters }) => {
  const isActive = filters.find(f => f.type === 'sports' && f.name === sport)

  return (
    <span
      className="pad-5 pad-left-10 pad-right-10 rounded pointer mar-right-10 sm-90"
      style={
        {
          border: isActive ? '1px solid #ff479c' : '1px solid #CDDCEF',
          color: isActive ? '#ff479c' : '#CDDCEF',
        }
      }
      onClick={() => updateFilters({name: sport, type: 'sports'})}
    >
      <span className={returnSportIcon(sport.toLowerCase())} />
      {' '}
      {sport.toUpperCase()}
    </span>
  )
}

const SportsSection = ({ sports, filters, updateFilters }) => {

  if (sports === '*') {
    const isActive = filters.find(f => f.type === 'sports' && f.name === '*')

    return (
      <span
      className="pad-5 pad-left-10 pad-right-10 rounded pointer mar-right-10 sm-90"
      style={
        {
          border: isActive ? '1px solid #ff479c' : '1px solid #CDDCEF',
          color: isActive ? '#ff479c' : '#CDDCEF',
        }
      }
        onClick={() => updateFilters({name: '*', type: 'sports'})}
      >
        All Sports
      </span>
    )
  }

  return (
    <>
      {
        sports.split(',').map(sport => {
          return (
            <SportItem
              key={sport}
              sport={sport}
              updateFilters={updateFilters}
              filters={filters}
            />
          )
        })
      }
    </>
  )
}

export default SportsSection

import React, { useState, useEffect } from 'react'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button
} from 'reactstrap'
import dateformat from 'dateformat'
import {
  size,
  startCase
} from 'lodash'

import GamestateSlider from './slider'
import CurrentGamestate from './gamestate/container'
import SliderMark from './slider-mark'
import ReplayURL from './replay-url'

import { SPORTS } from '../../helpers/constants'

// currently not in use, as there are no custom events
const collectMarks = gamestateData => gamestateData.reduce((a, c) => {
  // gamestate arary index key (not actual array index) starts at 1 not 0...
  if (c.customEvents) a[ c.index - 1 ] = (<SliderMark gameEvent={c} />)

  return a
}, {})

const formatContext = timestamp => {
  if (!timestamp) return '...'

  return dateformat(new Date(timestamp * 1000), 'yyyy-mm-dd hh:MM:ss', true)
}

const ReplayCard = ({
  replayStatus,
  replayCurrentIndex,
  updateReplayCurrentIndex,
  replayURL,
  gamestateData,
  context,
  selectedGame,
  sport,
  selectType,
  selectedType
}) => {
  const [ sliderChangeIndex, setSliderChangeIndex ] = useState(null)
  const [ marks, setMarks ] = useState({})
  const [ marksEventId, setMarksEventId ] = useState(null)

  useEffect(() => {
    if ((size(marks) === 0 && gamestateData) || (selectedGame && marksEventId !== selectedGame.eventId)) {
      setMarks(collectMarks(gamestateData))
      setMarksEventId(selectedGame.eventId)
    }
  }, [ gamestateData, marksEventId ])

  if ((replayStatus !== 'started' && replayStatus !== 'paused')
    || !gamestateData) return null

  const submitCurrentIndexUpdate = i => {
    if (i === replayCurrentIndex) return

    setSliderChangeIndex(null)
    updateReplayCurrentIndex(gamestateData[ i ].gamestate.timestamp)
  }

  let timestamp = null

  if (sliderChangeIndex) {
    timestamp = gamestateData[ sliderChangeIndex ].gamestate.timestamp
  } else if (context) {
    timestamp = context.live
  }

  const buttonTypeText = type => {
    if (type === 'offense') return 'Results - Offense'
    if (type === 'defense') return 'Results - Defense'
    if (type === 'st') return 'Results - S/T'

    return startCase(type)
  }

  return (
    <Card
      className="white"
      style={{ backgroundColor: '#475161', borderRadius: '8px', border: 'none', width: '100%' }}
    >
      <CardHeader
        style={{
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
          backgroundColor: '#475161'
        }}
      >
        <Row className="pad-bottom-10">
          {
            selectedGame && selectedGame.sport === SPORTS.NFL && selectedGame.type === 'all' ? [ 'inplay', 'prematch', 'players', 'games', 'teams', 'offense', 'defense', 'st' ].map(type => (

              <Button
                key={`button-${ type }`}
                outline
                size="sm"
                onClick={() => selectType(type)}
                style={{
                  cursor: 'pointer',
                  backgroundColor: selectedType === type ? '#20a8d8' : null,
                  border: selectedType !== type ? '1px solid #20a8d8' : null,
                  color: selectedType !== type ? '#20a8d8' : '#fff',
                  borderRadius: 5,
                  margin: 5,
                  padding: 5
                }}
              >
                {buttonTypeText(type)}
              </Button>

            ))
              : null
          }
        </Row>
        <ReplayURL replayURL={replayURL} />
      </CardHeader>
      <CardHeader
        style={{
          backgroundColor: '#475161',
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px'
        }}
        className="white"
      >
        <Row>
          <Col md={3}>
            <b>Live Context: </b>
            {
              formatContext(timestamp)
            }
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="replay-card-outer">

        <Row style={{ height: '75%' }}>
          <Col md={{ size: 10, offset: 1 }}>
            <CurrentGamestate
              currentGamestate={gamestateData[ sliderChangeIndex || replayCurrentIndex ]}
              sport={sport}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <GamestateSlider
              gamestateData={gamestateData}
              marks={marks}
              replayCurrentIndex={sliderChangeIndex || replayCurrentIndex}
              updateReplayCurrentIndex={submitCurrentIndexUpdate}
              updateSlideCurrentIndex={setSliderChangeIndex}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ReplayCard

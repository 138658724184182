import React from 'react'

import {
  groupBy,
  size
} from 'lodash'

import { useHistory } from 'react-router-dom'

import { SPORTS } from '../../../../helpers/constants'
import MainSideBar from '../main'

import { useAccount } from '../../../../context/account-context'

import {
  pathnameToEndpoint
} from '../../../../helpers/general'

import DocsSection from './section'

import SportsDropdown from './sports-dropdown'

const productSuiteOrder = {
  metadata: 0,
  'player props': 1,
  'match markets': 2,
  'bet builder': 3,
  'pre built parlays': 4,
  results: 5
}

const getSport = pathname => {
  if (!pathname) return null
  const sports = Object.values(SPORTS)

  const sport = sports.find(s => pathname.toLowerCase().indexOf(s) >= 0)

  return sport || 'all'
}

const DocsSideBar = ({ pathname }) => {
  const { accountRoutes } = useAccount()
  const history = useHistory()
  let sport = getSport(pathname)
  let currentRoute = accountRoutes
    ? accountRoutes.find(a => {
      const p = `/${ pathnameToEndpoint(pathname) }`

      return a.route === p
    }) : null

  if (
    !currentRoute &&
    accountRoutes &&
    pathnameToEndpoint(pathname).includes(`bet-request/${ sport }`)
  ) {
    currentRoute = accountRoutes.find(a => (
      a.productSuite === 'bet builder' && a.sidebarTitle === 'Bet Request'
    ))
  }

  if (
    !currentRoute &&
    accountRoutes &&
    pathnameToEndpoint(pathname).includes(`bet-request/results/${ sport }`)
  ) {
    currentRoute = accountRoutes.find(a => (
      a.productSuite === 'bet builder' && a.sidebarTitle === 'Results'
    ))
  }

  if (
    !currentRoute &&
    accountRoutes &&
    pathnameToEndpoint(pathname).includes('bet-request/inplay')
  ) {
    currentRoute = accountRoutes.find(a => (
      a.productSuite === 'bet builder'
      && a.sidebarTitle === 'In-Play Bet Request'
    ))

    if (pathnameToEndpoint(pathname).includes(SPORTS.NBA)) {
      sport = SPORTS.NBA
    } else if (pathnameToEndpoint(pathname).includes(SPORTS.NFL)) {
      sport = SPORTS.NFL
    } else {
      sport = SPORTS.MLB
    }
  }

  const groups = groupBy(accountRoutes, 'productSuite')

  const updateSport = updatedSport => {

    const updatedRoute = accountRoutes.find(a => (
      a.productSuite === currentRoute.productSuite &&
      a.sidebarTitle === currentRoute.sidebarTitle &&
      a.sport === updatedSport
    ))

    if (updatedRoute) {

      if (updatedRoute.sport === SPORTS.CFB && updatedRoute.productSuite === 'bet builder') {
        history.push('/docs/bet-request/cfb')
      } else if (updatedRoute.sport === SPORTS.CBB && updatedRoute.productSuite === 'bet builder') {
        history.push('/docs/bet-request/cbb')
      } else {
        history.push(`/docs/${ updatedRoute.route.substr(1).split('/').join('-') }`)
      }


      return
    }

    if ([ SPORTS.ATP, SPORTS.WTA, SPORTS.MLB, SPORTS.CFB, SPORTS.CBB ].includes(updatedSport)) {
      history.push(`/docs/${ updatedSport }-players-props`)
    } else {
      history.push(`/docs/${ updatedSport }-players-props-market-adjusted`)
    }
  }

  return (
    <div className="mar-top-30">
      <SportsDropdown
        sport={sport}
        updateSport={updateSport}
      />
      {
        size(groups) > 0 ? Object.keys(productSuiteOrder).map(k => (
          <DocsSection
            key={k}
            productSuite={k}
            routes={groups[ k ]}
            sport={sport}
            currentRoute={currentRoute}
          />
        )) : null
      }

      {
        !sport
          ? <MainSideBar />
          : null
      }
    </div>
  )
}

export default DocsSideBar

import React, { Component } from 'react'
import Markdown from 'markdown-to-jsx'

import {
  Card,
  CardBody
} from 'reactstrap'

import {
  Row,
  Column
} from 'simple-flexbox'

import {
  ThreeDots
} from 'react-loader-spinner'

import {
  ReferenceTable,
  SportSelection,
  TypeDropdown,
  ReferenceSearchBar
} from '../components/reference'

import IconSearch from '../assets/icon-search'

import {
  returnSpawnApiUrl
} from '../helpers/urls'

import { SPORTS } from '../helpers/constants'

const loadDoc = cb => {
  fetch(`${ returnSpawnApiUrl() }/docs/get/refs-reference`)
    .then(res => res.json())
    .then(res => {
      if (res && res.status) cb(null, res.data.results)

      return cb(true)
    })
    .catch(err => {
      console.log(err)
    })
}

const loadRefGroups = cb => {
  fetch(`${ returnSpawnApiUrl() }/reference/groups`)
    .then(res => res.json())
    .then(res => {
      if (res && res.status) cb(null, res.data.results)

      return cb(true)
    })
    .catch(err => {
      console.log(err)
    })
}

const formatRefGroups = groups => {
  return groups.reduce((a, c) => {
    const splitSports = c.sport.split(',')

    splitSports.forEach(s => {
      if (a.findIndex(f => (
        f.referenceGroup.toLowerCase() === c.referenceGroup.toLowerCase()
        && f.sport === s
      )) === -1) {
        a.push({ referenceGroup: c.referenceGroup, sport: s })
      }
    })

    return a
  }, [])
}

class Reference extends Component {
  constructor (props) {
    super(props)

    this.state = {
      requestedType: props.match.params.type || 'gameStatusId',
      sport: props.match.params.sport || SPORTS.MLB,
      referenceGroups: [],
      markdownContent: '',
      search: '',
      loading: false
    }

    this.setSport = this.setSport.bind(this)
    this.setRequestedType = this.setRequestedType.bind(this)
    this.setSearch = this.setSearch.bind(this)
  }

  componentDidMount () {
    this.setState({ loading: true })
    loadDoc((err, res) => {
      if (err) return
      this.setState({
        markdownContent: res,
        loading: false
      })
    })

    loadRefGroups((err, res) => {
      if (err) return
      const { requestedType, sport } = this.state

      let refMatch = res.find(r => (
        r.referenceGroup.toLowerCase() === requestedType.toLowerCase()
      ))

      if (!refMatch) {
        refMatch = res.find(r => r.sport === sport)
      }

      this.setState({
        referenceGroups: formatRefGroups(res),
        requestedType: refMatch.referenceGroup
      })
    })
  }

  componentDidUpdate (prevProps) {
    if (
      prevProps.match.params.type !== this.props.match.params.type
    ) {
      const type = this.props.match.params.type
      const sport = this.props.match.params.sport
      const { referenceGroups } = this.state

      let refMatch = referenceGroups.find(r => (
        (r.referenceGroup || '').toLowerCase() === (type || '').toLowerCase()
      ))

      if (!refMatch) {
        refMatch = referenceGroups.find(r => r.sport === sport)
      }

      this.setState({
        requestedType: refMatch.referenceGroup,
        sport: this.props.match.params.sport
      })
    } else if (prevProps.match.params.sport !== this.props.match.params.sport) {
      this.setState({
        sport: this.props.match.params.sport
      })
    }
  }

  setSport (sport) {
    const { requestedType, referenceGroups } = this.state
    let newRequestedType = referenceGroups.find(r => r.referenceGroup === requestedType && r.sport === sport)

    if (!newRequestedType) {
      newRequestedType = referenceGroups.find(r => r.sport === sport)
    }

    this.setState({
      sport,
      requestedType: newRequestedType.referenceGroup
    })
  }

  setRequestedType (requestedType) {
    this.setState({
      requestedType
    })
  }

  setSearch (search) {
    this.setState({
      search
    })
  }

  render () {
    const {
      requestedType,
      referenceGroups,
      markdownContent,
      sport,
      search,
      loading
    } = this.state

    return (
      <div>
        <Column flexGrow={1}>

          <h1 className="pt-3 klavika-regular white"><b>Reference</b></h1>
          <Card className="rounded" style={{ backgroundColor: '#374150' }}>
            <CardBody>
              <Row>
                <Column flex="5">
                  <SportSelection sport={sport} setSport={this.setSport} />
                </Column>
                <Column flex="3">
                  <TypeDropdown
                    referenceGroups={referenceGroups}
                    requestedType={requestedType}
                    setRequestedType={this.setRequestedType}
                    sport={sport}
                  />
                </Column>
                <Column className="pad-top-10">
                  <IconSearch />
                </Column>
                <Column
                  flex="3"
                  className="bord-bottom-grey"
                  style={{ height: '35px' }}
                >
                  <ReferenceSearchBar
                    search={search}
                    setSearch={this.setSearch}
                  />
                </Column>
              </Row>
            </CardBody>
          </Card>

          {
            loading
              ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ThreeDots
                    height="100"
                    width="100"
                    radius="9"
                    color="#ffffff"
                    visible
                    ariaLabel="three-dots-loading"
                  />
                </div>
              ) : (
                <Markdown
                  options={{
                    overrides: {
                      ReferenceTable: {
                        component: ReferenceTable,
                        props: {
                          requestedType,
                          search,
                          sport
                        }
                      }
                    }
                  }}
                >
                  { markdownContent }
                </Markdown>
              )
          }
        </Column>
      </div>
    )
  }
}




export default Reference

import { SPORTS } from "./constants"

export const replaySocketURL = () => {
  const env = process.env.REACT_APP_ENV

  switch (env) {
  case 'local':
    return 'http://localhost:8182'
  case 'development':
    return 'https://replay-sockets.dev.swishanalytics.com'
  case 'ci':
  case 'stage':
    return 'https://replay-sockets.swishanalytics.com'
  default:
    return 'https://replay-sockets.swishanalytics.com'
  }
}

export const replayClientURL = (uniq, apikey, selectedGame, selectedType) => {
  const env = process.env.REACT_APP_ENV

  const makeURL = b => {
    if (selectedGame && selectedGame.sport === SPORTS.NFL && selectedGame.type === 'all') {
      switch (selectedType) {
        case 'prematch': return `https://${ b }/nfl/players/props/market/adjusted?apikey=${ apikey.apikey }&game=${selectedGame.eventId}`
        case 'inplay': return `https://${ b }/nfl/players/props/inplay?apikey=${ apikey.apikey }&game=${selectedGame.eventId}`
        case 'players': return `https://${ b }/nfl/players?apikey=${ apikey.apikey }`
        case 'teams': return `https://${ b }/nfl/teams?apikey=${ apikey.apikey }`
        case 'games': return `https://${ b }/nfl/games?apikey=${ apikey.apikey }&gameId=${selectedGame.eventId}`
        case 'offense': return `https://${ b }/nfl/players/props/results/offense?apikey=${ apikey.apikey }&game=${selectedGame.eventId}&dataConfirmed=all`
        case 'defense': return `https://${ b }/nfl/players/props/results/defense?apikey=${ apikey.apikey }&game=${selectedGame.eventId}&dataConfirmed=all`
        case 'st': return `https://${ b }/nfl/players/props/results/specialteams?apikey=${ apikey.apikey }&game=${selectedGame.eventId}&dataConfirmed=all`
        default: return `https://${ b }/${ selectedGame.sport }/players/props/inplay${ selectedGame.type === 'rog' ? '/cumulative' : '' }?apikey=${ apikey.apikey }&${(selectedGame.sport === SPORTS.NFL || selectedGame.sport === SPORTS.NBA || selectedGame.type === 'rog') ? 'game' : 'gameId'}=${selectedGame.eventId}`
      }
    }

    return `https://${ b }/${ selectedGame.sport }/players/props/inplay${ selectedGame.type === 'rog' ? '/cumulative' : '' }?apikey=${ apikey.apikey }&${(selectedGame.sport === SPORTS.NFL || selectedGame.sport === SPORTS.NBA || selectedGame.type === 'rog') ? 'game' : 'gameId'}=${selectedGame.eventId}`
  }
  if (typeof uniq === 'string' && uniq.includes('.swishanalytics.com')) {

    return makeURL(uniq)
  }

  switch(env) {
  case 'production':

    return makeURL('replay.swishanalytics.com')
  default:

    return makeURL('replay.swishanalytics.com')
  }
}

export const returnFormattedQuarter = q => {
  if (q === 5) return 'OT'
  if (q > 5) return `${ q - 4 }OT`

  return `${ q }Q`
}

export const formatSeconds = s => (s < 10 ? `0${ s }` : s)

export const orindal = i => {
  const j = i % 10
  const k = i % 100

  if (j === 1 && k !== 11) {
    return `${ i }st`
  }
  if (j === 2 && k !== 12) {
    return `${ i }nd`
  }
  if (j === 3 && k !== 13) {
    return `${ i }rd`
  }

  return `${ i }th`
}


export const splitYardLine = y => {
  if (!y) return null

  return (
    `${ y.match(/[a-z]+|[^a-z]+/gi)[ 0 ] } ${ y.match(/[a-z]+|[^a-z]+/gi)[ 1 ] }`
  )
}

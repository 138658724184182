import React, { useEffect, useRef } from 'react'
import { Column, Row } from 'simple-flexbox'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { scroller, Element } from 'react-scroll'
// import ContentSample from '../components/content/content'

const DocsLanding = ({ match }) => {

  const executeScroll = scrollTo => {
    window.history.pushState(
      null, null, `/docs-landing/${ scrollTo.replace('Section', '') }`
    )

    scroller.scrollTo(scrollTo, {
      duration: 750,
      delay: 25,
      smooth: true
    })
  }

  // Init Load
  useEffect(() => {
    if (match.params.sport) {
      scroller.scrollTo(`${ match.params.sport }Section`)
    } else {
      window.scrollTo(0, 0)
    }
  }, [])

  return (
    <div className="white">


      <Column flexGrow={2}>

        <div className="pad-10">
          <h1 className="pt-3 klavika-regular">
            <b>Swish API Documentation</b>
          </h1>
          <h4 className="lh-25 klavika-regular light-blue">
              Integrate all pre-match and in-play
              markets for NBA, NFL, MLB, NHL, NCAA Basketball, NCAA Football and
              ATP Tennis. Our API Reference is organized around basic
              integration workflows:
               setting up new markets, ingesting and updating prices, mapping
               to existing games, teams and players, etc.  The Swish API
               follows the general patterns of REST.
          </h4>

        </div>


        <Row horizontal="start" className="mar-top-0 mar-bottom-30">

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">

            <Button
              className={
                `sport-endpoint-button-bg-landing nba-orange-border-top
                nba-orange-back mar-top-10 mar-bottom-10`
              }
              href=""
              onClick={() => executeScroll('nbaSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nba.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>NBA</b>

            </Button>
          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
            <Button
              className="sport-endpoint-button-bg-landing nfl-green-border-top nfl-green-back mar-top-10 mar-bottom-10"
              href=""
              onClick={() => executeScroll('nflSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nfl.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>NFL</b>

            </Button>
          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
            <Button
              className="sport-endpoint-button-bg-landing mlb-red-border-top mlb-red-back mar-top-10 mar-bottom-10"
              href=""
              onClick={() => executeScroll('mlbSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/mlb.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>MLB</b>

            </Button>
          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
            <Button
              className="sport-endpoint-button-bg-landing nhl-blue-border-top nhl-blue-back mar-top-10 mar-bottom-10"
              href=""
              onClick={() => executeScroll('nhlSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nhl.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>NHL</b>

            </Button>
          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
            <Button
              className="sport-endpoint-button-bg-landing cbb-blue-border-top cbb-blue-back mar-top-10 mar-bottom-10"
              href=""
              onClick={() => executeScroll('cbbSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cbb.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>CBB</b>

            </Button>
          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
            <Button
              className="sport-endpoint-button-bg-landing cfb-green-border-top cfb-green-back mar-top-10 mar-bottom-10"
              href=""
              onClick={() => executeScroll('cfbSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cfb.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>CFB</b>

            </Button>
          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
            <Button
              className="sport-endpoint-button-bg-landing atp-green-border-top atp-green-back mar-top-10 mar-bottom-10"
              href=""
              onClick={() => executeScroll('atpSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/atp.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>ATP</b>

            </Button>
          </Column>
          
          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0">
            <Button
              className="sport-endpoint-button-bg-landing wta-orange-border-top wta-orange-back mar-top-10 mar-bottom-10"
              href=""
              onClick={() => executeScroll('wtaSection')}
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/wta.png"
                width="auto"
                height="37"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <br/>
              <b>WTA</b>

            </Button>
          </Column>


        </Row>
        { /*** BUTTON ROW END ***/ }


        {/*** NBA SECTION START ***/}
        <Element name="nbaSection" />
        <Row
          horizontal="start"
          className="mar-top-0 mar-bottom-0 klavika-regular"
        >

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 nba-orange-border-top nba-orange-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nba.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180">NBA</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">


          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10">
              <b>Player Props</b>
            </div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-players-props-market-adjusted"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">
                    Points, Rebounds, Assists  & More
                  </div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-players-props-inplay"
                >
                  IN-PLAY PROPS
                  <div className="sm-90 fw-300">Live Player Markets</div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-players-props-alternates"
                >
                ALTERNATE PLAYER PROPS
                  <div className="sm-90 fw-300">Pre-Match Alternate Lines</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-players-props-market-adjusted-historical"
                >
                  HISTORICAL PLAYER PROPS
                  <div className="sm-90 fw-300">Historical Lines & Prices</div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-players-props-alternates-inplay"
                >
                IN-PLAY ALTERNATE PLAYER PROPS
                <sup className="sm-60 yellow pad-left-5">NEW</sup>
                <div className="sm-90 fw-300">In-Play Alternate Lines</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0" />

              </Row>


            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Same Game Parlays</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request/nba"
                >
                  BET REQUEST (SGP)
                  <div className="sm-90 fw-300">Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-requests-accumulators"
                >
                  PRE-BUILT PARLAYS
                  <div className="sm-90 fw-300">
                    Pre-Canned Daily Combinations
                  </div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request-inplay/nba"
                >
                  IN-PLAY BET REQUEST (SGP)
                  <sup className="sm-60 yellow pad-left-5">NEW</sup>
                  <div className="sm-90 fw-300">In-Play Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0" />
            </Row>


            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Match markets</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-matches-markets-prematch"
                >
                  PRE-MATCH MATCH MARKETS
                  <div className="sm-90 fw-300">Game and Team Markets</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nba-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/nba-matches-markets-inplay"
                >
                  IN-PLAY MATCH MARKETS
                  <sup className="sm-60 yellow pad-left-5">NEW</sup>
                  <div className="sm-90 fw-300">Live Game and Team Markets</div>
                </Button>
              </Column>

            </Row>


          </Column>


          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs nba-orange-border-top mar-top-10 mar-bottom-0"
              href="/docs/nba-players-props-results"
            >
              PLAYER PROPS RESULTS
              <div className="sm-90 fw-300">
                Results for All Player Props Markets
              </div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nba-orange-border-top mar-top-10 mar-bottom-0"
              href="/docs/bet-request-results/nba"
            >
              BET REQUEST RESULTS
              <div className="sm-90 fw-300">Parlays Results</div>
            </Button>


            <Button
              className="sport-endpoint-button-docs nba-orange-border-top mar-top-10 mar-bottom-0"
              href="/docs/nba-matches-markets-prematch-results"
            >
              TEAM & MATCH RESULTS
              <div className="sm-90 fw-300">Match Market Results</div>
            </Button>


          <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs nba-orange-border-top mar-top-10 mar-bottom-0"
              href="/docs/nba-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nba-orange-border-top mar-top-10 mar-bottom-0"
              href="/docs/nba-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nba-orange-border-top mar-top-10 mar-bottom-10"
              href="/docs/nba-teams"
            >
              TEAMS
              <div className="sm-90 fw-300">Team Mapping</div>
            </Button>

          </Column>


        </Row>
        {/*** NBA SECTION END ***/}


        {/*** NFL SECTION START ***/}
        <Element name="nflSection" />
        <Row
          horizontal="start"
          className="mar-top-50 mar-bottom-0 klavika-regular"
        >

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 nfl-green-border-top nfl-green-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nfl.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180 pad-left-5">NFL</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">


          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10"><b>Player Props</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-players-props-market-adjusted"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">Anytime TDs, Passing Yards ... 30+ Stat Types</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-players-props-inplay"
                >
                  IN-PLAY PROPS
                  <div className="sm-90 fw-300">Live Player Markets</div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-players-props-alternates"
                >
                  ALTERNATE PLAYER PROPS
                  <div className="sm-90 fw-300">Pre-Match Alternate Lines</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-players-props-historical"
                >
                  HISTORICAL PLAYER PROPS
                  <div className="sm-90 fw-300">Historical Lines & Prices</div>
                </Button>
              </Column>

            </Row>


            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Same Game Parlays</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request/nfl"
                >
                  BET REQUEST (SGP)
                  <div className="sm-90 fw-300">Parlay Builder Engine</div>
                </Button>
              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-requests-accumulators"
                >
                  PRE-BUILT PARLAYS
                  <div className="sm-90 fw-300">Pre-Canned Daily Combinations</div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request-inplay/nfl"
                >
                  IN-PLAY BET REQUEST (SGP)
                  <div className="sm-90 fw-300">In-Play Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-accumulators-inplay"
                >
                  IN-PLAY PRE-BUILT PARLAYS
                  <sup className="sm-60 yellow pad-left-5">NEW</sup>
                  <div className="sm-90 fw-300">
                    Live Generated Parlay Combinations
                  </div>
                </Button>
              </Column>

            </Row>


            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Match markets</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-matches-markets-prematch"
                >
                  PRE-MATCH MATCH MARKETS
                  <div className="sm-90 fw-300">Game and Team Markets</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-matches-markets-inplay"
                >
                  IN-PLAY MATCH MARKETS
                  <div className="sm-90 fw-300">Live Game and Team Markets</div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nfl-green-border-top mar-10 mar-bottom-0"
                  href="/docs/nfl-matches-markets-prematch-alternates"
                >
                  PRE-MATCH MATCH MARKETS ALTERNATES
                  <sup className="sm-60 yellow pad-left-5">NEW</sup>
                  <div className="sm-90 fw-300">Game and Team Market Alternate Lines</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0" />
            </Row>

          </Column>


          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/nfl-players-props-results-offense"
            >
              OFFENSE PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Offense Player Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/nfl-players-props-results-defense"
            >
              DEFENSE PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Defense Player Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/nfl-players-props-results-specialteams"
            >
              SPECIAL TEAMS PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Special Teams Player Props</div>
            </Button>


            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/bet-request-results/nfl"
            >
              BET REQUEST RESULTS
              <div className="sm-90 fw-300">Parlays Results</div>
            </Button>


            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/nfl-matches-markets-prematch-results"
            >
              MATCH RESULTS
              <div className="sm-90 fw-300">Match Market Results</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/nfl-matches-markets-prematch-results"
            >
              TEAM PROPS RESULTS
              <div className="sm-90 fw-300">Team Props Results</div>
            </Button>


          <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/nfl-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/nfl-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nfl-green-border-top mar-top-10 mar-bottom-10"
              href="/docs/nfl-teams"
            >
              TEAMS
              <div className="sm-90 fw-300">Team Mapping</div>
            </Button>

          </Column>

        </Row>
        {/*** NFL SECTION END ***/}




        {/*** MLB SECTION START ***/}
        <Element name="mlbSection" />
        <Row
          horizontal="start"
          className="mar-top-50 mar-bottom-0 klavika-regular"
        >

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 mlb-red-border-top mlb-red-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/mlb.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180 pad-left-10">MLB</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">


          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10"><b>Player Props</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-players-props"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">Home Runs, Strikeouts & More</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-players-props-inplay-cumulative"
                >
                  IN-PLAY PROPS
                  <div className="sm-90 fw-300">Live Rest of Game Player Markets</div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-players-props-inplay"
                >
                  AT-BAT PLAYER PROPS
                  <div className="sm-90 fw-300">Pitch-by-Pitch Markets During Every At Bat</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-players-props-alternates"
                >
                  ALTERNATE PLAYER PROPS
                  <div className="sm-90 fw-300">Pre-Match Alternate Lines</div>
                </Button>
              </Column>

            </Row>


            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-players-props-historical"
                >
                  HISTORICAL PLAYER PROPS
                  <div className="sm-90 fw-300">Historical Lines & Prices</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-players-props-alternates-inplay"
                >
                IN-PLAY ALTERNATE PLAYER PROPS
                <sup className="sm-60 yellow pad-left-5">NEW</sup>
                <div className="sm-90 fw-300">In-Play Alternate Lines</div>
                </Button>

              </Column>

            </Row>

            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Same Game Parlays</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request/mlb"
                >
                  BET REQUEST (SGP)
                  <div className="sm-90 fw-300">Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-requests-accumulators"
                >
                  PRE-BUILT PARLAYS
                  <div className="sm-90 fw-300">Pre-Canned Daily Combinations</div>
                </Button>
              </Column>

            </Row>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request-inplay/mlb"
                >
                  IN-PLAY BET REQUEST (SGP)
                  <sup className="sm-60 yellow pad-left-5">NEW</sup>
                  <div className="sm-90 fw-300">In-Play Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0 disabled"
                  href="#"
                >
                  IN-PLAY PRE-BUILT PARLAYS
                  <div className="sm-90 fw-300">Coming Soon</div>
                </Button>
              </Column>

            </Row>


            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Match markets</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-matches-markets-prematch"
                >
                  PRE-MATCH MATCH MARKETS
                  <div className="sm-90 fw-300">Game and Team Markets</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs mlb-red-border-top mar-10 mar-bottom-0"
                  href="/docs/mlb-matches-markets-inplay"
                >
                  IN-PLAY MATCH MARKETS
                  <div className="sm-90 fw-300">Live Game and Team Markets</div>
                </Button>
              </Column>

            </Row>


          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-0"
              href="/docs/mlb-players-props-results-batters"
            >
              BATTERS PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Batter Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-0"
              href="/docs/mlb-players-props-results-pitchers"
            >
              PITCHERS PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Pitcher Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-0"
              href="/docs/bet-request-results/mlb"
            >
              BET REQUEST RESULTS
              <div className="sm-90 fw-300">Parlays Results</div>
            </Button>


            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-0"
              href="/docs/mlb-matches-markets-prematch-results"
            >
              MATCH RESULTS
              <div className="sm-90 fw-300">Match Market Results</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-0"
              href="/docs/mlb-matches-markets-prematch-results"
            >
              TEAM PROPS RESULTS
              <div className="sm-90 fw-300">Team Props Results</div>
            </Button>


          <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-0"
              href="/docs/mlb-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-0"
              href="/docs/mlb-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs mlb-red-border-top mar-top-10 mar-bottom-10"
              href="/docs/mlb-teams"
            >
              TEAMS
              <div className="sm-90 fw-300">Team Mapping</div>
            </Button>

          </Column>

        </Row>
        {/*** MLB SECTION END ***/}


        {/*** NHL SECTION START ***/}
        <Element name="nhlSection" />
        <Row horizontal="start" className="mar-top-50 mar-bottom-0 klavika-regular">

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 nhl-blue-border-top nhl-blue-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/nhl.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180 pad-left-10">NHL</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">


          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10">
              <b>Player Props</b>
            </div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nhl-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/nhl-players-props-market-adjusted"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">Goals, Goalies Saves, Shots & More</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">

              </Column>

            </Row>


            <div className="klavika-regular bg-160 pad-left-10 pad-top-25">
              <b>Same Game Parlays</b>
            </div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nhl-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request/nhl"
                >
                  BET REQUEST (SGP)
                  <div className="sm-90 fw-300">Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs nhl-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/nhl-requests-accumulators"
                >
                  PRE-BUILT PARLAYS
                  <div className="sm-90 fw-300">Pre-Canned Daily Combinations</div>
                </Button>
              </Column>

            </Row>

            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Match markets</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs nhl-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/nhl-matches-markets-prematch"
                >
                  PRE-MATCH MATCH MARKETS
                  <div className="sm-90 fw-300">Game and Team Markets</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0"/>

            </Row>


          </Column>


          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs nhl-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/nhl-players-props-results-skaters"
            >
              SKATERS PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Skater Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nhl-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/nhl-players-props-results-goalies"
            >
              GOALIES PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Goalie Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nhl-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/bet-request-results/nhl"
            >
              BET REQUEST RESULTS
              <div className="sm-90 fw-300">Parlays Results</div>
            </Button>


          <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs nhl-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/nhl-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nhl-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/nhl-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs nhl-blue-border-top mar-top-10 mar-bottom-10"
              href="/docs/nhl-teams"
            >
              TEAMS
              <div className="sm-90 fw-300">Team Mapping</div>
            </Button>

          </Column>

        </Row>
        {/*** NHL SECTION END ***/}


        {/*** CBB SECTION START ***/}
        <Element name="cbbSection" />
        <Row horizontal="start" className="mar-top-50 mar-bottom-0 klavika-regular">

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 cbb-blue-border-top cbb-blue-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cbb.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180 pad-left-10">NCAA BASKETBALL</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">
          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10">
              <b>Player Props</b>
            </div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs cbb-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/cbb-players-props"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">Points, Assists, Rebounds & more</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs cbb-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/cbb-players-props-alternates"
                >
                  ALTERNATE PLAYER PROPS
                  <sup className="sm-60 yellow pad-left-5">NEW</sup>
                  <div className="sm-90 fw-300">Pre-Match Alternate Lines</div>
                </Button>
              </Column>

            </Row>

            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Same Game Parlays</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs cbb-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request/cbb"
                >
                  BET REQUEST (SGP)
                  <div className="sm-90 fw-300">Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs cbb-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/cbb-requests-accumulators"
                >
                  PRE-BUILT PARLAYS
                  <div className="sm-90 fw-300">Pre-Canned Daily Combinations</div>
                </Button>
              </Column>

            </Row>

            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Match markets</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs cbb-blue-border-top mar-10 mar-bottom-0"
                  href="/docs/cbb-matches-markets-prematch"
                >
                  PRE-MATCH MATCH MARKETS
                  <div className="sm-90 fw-300">Game and Team Markets</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0"/>

            </Row>

          </Column>


        <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs cbb-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/cbb-players-props-results"
            >
              PLAYER PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Player Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs cbb-blue-border-top mar-top-10 mar-bottom-0 disabled"
              href="#"
            >
              BET REQUEST RESULTS
              <div className="sm-90 fw-300">Coming Soon</div>
            </Button>


          <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs cbb-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/cbb-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs cbb-blue-border-top mar-top-10 mar-bottom-0"
              href="/docs/cbb-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs cbb-blue-border-top mar-top-10 mar-bottom-10"
              href="/docs/cbb-teams"
            >
              TEAMS
              <div className="sm-90 fw-300">Team Mapping</div>
            </Button>

          </Column>

        </Row>
        {/*** CBB SECTION END ***/}


        {/*** CFB SECTION START ***/}
        <Element name="cfbSection" />
        <Row horizontal="start" className="mar-top-50 mar-bottom-0 klavika-regular">

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 cfb-green-border-top cfb-green-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/cfb.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180 pad-left-10">NCAA FOOTBALL</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">
          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10"><b>Player Props</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs cfb-green-border-top mar-10 mar-bottom-0"
                  href="/docs/cfb-players-props"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">Passing Yards, Receiving TDs & more</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs cfb-green-border-top mar-10 mar-bottom-0"
                  href="/docs/cfb-players-props-alternates"
                >
                  ALTERNATE PLAYER PROPS
                  <div className="sm-90 fw-300">Pre-Match Alternate Lines</div>
                </Button>
              </Column>

            </Row>

            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Same Game Parlays</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs cfb-green-border-top mar-10 mar-bottom-0"
                  href="/docs/bet-request/cfb"
                >
                  BET REQUEST (SGP)
                  <div className="sm-90 fw-300">Parlay Builder Engine</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs cfb-green-border-top mar-10 mar-bottom-0 disabled"
                  href="/docs/cfb-requests-accumulators"
                >
                  PRE-BUILT PARLAYS
                  <div className="sm-90 fw-300">Coming Soon</div>
                </Button>
              </Column>
            </Row>

            <div className="klavika-regular bg-160 pad-left-10 pad-top-25"><b>Match markets</b></div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs cfb-green-border-top mar-10 mar-bottom-0"
                  href="/docs/cfb-matches-markets-prematch"
                >
                  PRE-MATCH MATCH MARKETS
                  <div className="sm-90 fw-300">Game and Team Markets</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0"/>

            </Row>

          </Column>


          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs cfb-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/cfb-players-props-results-offense"
            >
              OFFENSE PLAYER PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Offense Player Props</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs cfb-green-border-top mar-top-10 mar-bottom-0 disabled"
              href="/docs/cfb-players-props-results-defense"
            >
              DEFENSE PLAYER PROPS RESULTS
              <div className="sm-90 fw-300">Coming Soon</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs cfb-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/bet-request-results/cfb"
            >
              BET REQUEST RESULTS
              <div className="sm-90 fw-300">Parlay Results</div>
            </Button>


          <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs cfb-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/cfb-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs cfb-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/cfb-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs cfb-green-border-top mar-top-10 mar-bottom-10"
              href="/docs/cfb-teams"
            >
              TEAMS
              <div className="sm-90 fw-300">Team Mapping</div>
            </Button>

          </Column>

        </Row>
        {/*** CFB SECTION END ***/}


        {/*** ATP SECTION START ***/}
        <Element name="atpSection" />
        <Row horizontal="start" className="mar-top-50 mar-bottom-0 klavika-regular">

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 atp-green-border-top atp-green-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/atp.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180 pad-left-10">ATP</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">


          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10">
              <b>Player Props</b>
            </div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs atp-green-border-top mar-10 mar-bottom-0"
                  href="/docs/atp-players-props"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">Aces, Games Won, Double Faults and More</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs atp-green-border-top mar-10 mar-bottom-0"
                  href="/docs/atp-players-props-inplay"
                >
                  IN-PLAY PROPS
                  <div className="sm-90 fw-300">Live Props During Every Game, Set and Match</div>
                </Button>
              </Column>
            </Row>


          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs atp-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/atp-players-props"
            >
              PLAYER PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Player Props</div>
            </Button>


          <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs atp-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/atp-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs atp-green-border-top mar-top-10 mar-bottom-10"
              href="/docs/atp-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

          </Column>

        </Row>
        {/*** ATP SECTION END ***/}


        {/*** WTA SECTION START ***/}
        <Element name="wtaSection" />
        <Row horizontal="start" className="mar-top-50 mar-bottom-0 klavika-regular">

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-15">
            <div
              className="sport-endpoint-button-docs pad-10 wta-orange-border-top wta-orange-back mar-top-10 mar-bottom-0"
            >
              <img
                src="https://swish-assets.s3-us-west-2.amazonaws.com/imgs/business/sportsbook/wta.png"
                width="auto"
                height="45"
                alt="Swish"
                className="mar-top-neg-8"
              />
              <b className="bg-180 pad-left-10">WTA</b>

            </div>
          </Column>
        </Row>


        <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular pad-10 pad-top-0">


          <Column flex="3" className="mar-0 mar-top-10">

            <div className="klavika-regular bg-160 pad-left-10">
              <b>Player Props</b>
            </div>

            <Row horizontal="start" className="mar-top-0 mar-bottom-0 klavika-regular">

              <Column flex="2" className="mar-0">

                <Button
                  className="sport-endpoint-button-docs wta-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/wta-players-props"
                >
                  PRE-MATCH PROPS
                  <div className="sm-90 fw-300">Aces, Games Won, Double Faults and More</div>
                </Button>

              </Column>

              <Column flex="2" className="mar-0 mar-top-0 mar-bottom-0">
                <Button
                  className="sport-endpoint-button-docs wta-orange-border-top mar-10 mar-bottom-0"
                  href="/docs/wta-players-props-inplay"
                >
                  IN-PLAY PROPS
                  <div className="sm-90 fw-300">Live Props During Every Game, Set and Match</div>
                </Button>
              </Column>
            </Row>


          </Column>

          <Column flex="1" className="mar-10 mar-top-0 mar-bottom-0 fw-300-back pad-20 pad-top-10 pad-bottom-10 light-blue-back rounded-docs">

            <div className="klavika-regular bg-160"><b>Results</b></div>

            <Button
              className="sport-endpoint-button-docs wta-green-border-top mar-top-10 mar-bottom-0"
              href="/docs/wta-players-props"
            >
              PLAYER PROPS RESULTS
              <div className="sm-90 fw-300">Results for All Player Props</div>
            </Button>

            <div className="klavika-regular bg-160 pad-top-25"><b>MetaData</b></div>

            <Button
              className="sport-endpoint-button-docs wta-orange-border-top mar-top-10 mar-bottom-0"
              href="/docs/wta-games"
            >
              GAMES
              <div className="sm-90 fw-300">Event Mapping</div>
            </Button>

            <Button
              className="sport-endpoint-button-docs wta-orange-border-top mar-top-10 mar-bottom-10"
              href="/docs/wta-players"
            >
              PLAYERS
              <div className="sm-90 fw-300">Player Mapping</div>
            </Button>

          </Column>

        </Row>
        {/*** WTA SECTION END ***/}


        <br/><br/><br/><br/><br/>


      </Column>

    </div>
  )
}

export default DocsLanding

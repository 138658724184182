import React from 'react'

import MLBGameState from './mlb'
import NFLGameState from './nfl'
import NBAGameState from './nba'

import { SPORTS } from '../../../helpers/constants'

const GameStateContainer = props => {
  if (props.sport === SPORTS.MLB) return <MLBGameState {...props} />
  if (props.sport === SPORTS.NFL) return <NFLGameState {...props} />
  if (props.sport === SPORTS.NBA) return <NBAGameState {...props} />

  return null
}

export default GameStateContainer

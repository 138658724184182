import React from 'react'

import {
  Button
} from 'reactstrap'
import { SPORTS } from '../../helpers/constants'

const SportSelection = ({ sport, setSport }) => {
  return (
    <div className="row">
      <Button
        color={sport === SPORTS.MLB ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.MLB)}
      >
        MLB
      </Button>
      <Button
        color={sport === SPORTS.NFL ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.NFL)}
      >
        NFL
      </Button>
      <Button
        color={sport === SPORTS.NBA ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.NBA)}
      >
        NBA
      </Button>
      <Button
        color={sport === SPORTS.NHL ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.NHL)}
      >
        NHL
      </Button>
      <Button
        color={sport === SPORTS.CBB ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.CBB)}
      >
        CBB
      </Button>
      <Button
        color={sport === SPORTS.CFB ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.CFB)}
      >
        CFB
      </Button>
      <Button
        color={sport === SPORTS.ATP ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.ATP)}
      >
        ATP
      </Button>
      <Button
        color={sport === SPORTS.WTA ? 'active-sport' : 'secondary'}
        outline
        className="mar-right-5 rounded"
        onClick={() => setSport(SPORTS.WTA)}
      >
        WTA
      </Button>
    </div>
  )
}

export default SportSelection
